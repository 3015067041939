@font-face {
  font-family: "Nunito";
  src: local("Nunito"), url("../main/material.theme/fonts/nunito/static/Nunito-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Nunito-Light";
  src: local("Nunito"), url("../main/material.theme/fonts/nunito/static/Nunito-ExtraLight.ttf") format("truetype");
}
@font-face {
  font-family: "Montserrat-Light";
  src: local("Montserrat"),
    url("../main/material.theme/fonts/montserrat/static/Montserrat-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
    url("../main/material.theme/fonts/montserrat/static/Montserrat-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Montserrat-SemiBold";
  src: local("Montserrat"),
    url("../main/material.theme/fonts/montserrat/static/Montserrat-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "Montserrat-Bold";
  src: local("Montserrat"), url("../main/material.theme/fonts/montserrat/static/Montserrat-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Inter";
  src: local("Inter"), url("../main/material.theme/fonts/inter/static/Inter-Light.ttf") format("truetype");
}
.vertical-line {
  border: 1px solid black;
  background: black;
  width: 1px;
}
.css-lv8za2-MuiCardMedia-root {
  object-fit: contain;
  width: 110px;
  height: 110px;
  // object-fit: fill;
}
.css-lv8za2-MuiCardMedia-root img {
  background-size: 100% 100%;
  background-color: #ffffff !important;
  // background-image: url("../icons/noimage.png");
}
.horizontal-line {
  width: 100%;
  border: 1px solid black;
  background: black;
}
.paper-root {
  box-shadow: 0px 3px 12px #c8cfde99 !important;
}
.font-weight-bolder {
  font-weight: bolder;
}
.h-40 {
  height: 40%;
}
.h-60 {
  height: 60%;
}
.h-20 {
  height: 20%;
}
.h-95 {
  height: 95%;
}
.h-10 {
  height: 10%;
}
.h-80 {
  height: 80%;
}
.h-60-vh {
  height: 60vh;
}
.h-75-vh {
  height: 75vh;
}
.min-h-100-vh {
  min-height: 100vh;
}
.stripe-pay-now-button{ 
  margin-top: 20px;
  justify-content: end;
  background: #7DFAA4;
  color: #000000;
  border-radius: 30px;
  outline: none;
  border: 0px;
  min-width: 25%;
  height: 38px;
}
.min-h-75-vh {
  min-height: 75vh;
}
.min-h-50-vh {
  min-height: 50vh;
}
.min-h-25 {
  min-height: 25vh;
}
.max-h-75-vh {
  max-height: 75vh;
}
.min-w-30 {
  width: 30% !important;
}
.min-w-40 {
  width: 40% !important;
}
.w-13 {
  width: 13% !important;
}
.w-15 {
  width: 15% !important;
}
.w-16 {
  width: 16% !important;
}
.w-10 {
  width: 10% !important;
}
.w-18 {
  width: 18% !important;
}
.w-30 {
  width: 30% !important;
}
.w-34 {
  width: 34% !important;
}
.w-35 {
  width: 35% !important;
}
.w-25 {
  width: 25% !important;
}
.w-36 {
  width: 36% !important;
}
.w-37 {
  width: 37% !important;
}
.w-20 {
  width: 20% !important;
}
.w-13 {
  width: 13% !important;
}
.w-33 {
  width: 33% !important;
}
.w-40 {
  width: 40% !important;
}
.w-45 {
  width: 45% !important;
}
.w-48 {
  width: 48% !important;
}
.w-49 {
  width: 49% !important;
}
.w-55 {
  width: 55% !important;
}
.w-70 {
  width: 70% !important;
}
.w-67 {
  width: 67% !important;
}
.w-65 {
  width: 65% !important;
}
.w-60 {
  width: 60% !important;
}
.w-87 {
  width: 87% !important;
}
.w-85 {
  width: 85% !important;
}
.w-80 {
  width: 80% !important;
}
.w-83 {
  width: 83% !important;
}
.w-90 {
  width: 90% !important;
}
.w-95 {
  width: 95% !important;
}
.bg-black {
  background-color: #000000;
}
.border-radius-100 {
  border-radius: 50% !important;
}
.rounded-md {
  border-radius: 15px;
}
.text-ellipsis-clamp-3 {
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.text-ellipsis-clamp-4 {
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.text-ellipsis-clamp-1 {
  white-space: normal;
  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  height: 20px;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.width-max-content {
  width: max-content;
}
.cursor-pointer {
  cursor: pointer;
}
.loading-message {
  justify-content: center;
  display: flex;
  color: #cac5c5;
}
.animation-height-400 {
  max-height: 100vh;
  overflow: auto;
  -webkit-transition: all .35s !important;
  -moz-transition: all .35s !important;
  -ms-transition: all .35s !important;
  -o-transition: all .35s !important;
  transition: all .35s !important;
}
.animation-height-0 {
  max-height: 0px;
  -webkit-transition: all .35s !important;
  -moz-transition: all .35s !important;
  -ms-transition: all .35s !important;
  -o-transition: all .35s !important;
  transition: all .35s !important;
}
.animation-initial-cubic-bezier-transition {
  transition: all 300ms cubic-bezier(0.34, 1.61, 0.7, 1) !important;
}
.animation-transform-up-cubix-transition {
  transform: translate3d(0, -30px, 0);
}
%animation-transform-up-cubix-transition {
  // try to import like this  @import filepath and use @extent to refer in file
  transform: translate3d(0, -30px, 0);
}
%animation-transform-scale {
  transform: scale(1.2);
}
.text-terms {
  color: #484848;
}
.loaderHeight {
  height: 75vh;
}
.containerHeight {
  height: 50vh !important;
  overflow: scroll;
  width: 100%;
}

.claimContainerHeight {
  height: 43vh !important;
  overflow: scroll;
  width: 100%;
  padding: 12px;
}
.damagesContainerHeight {
  height: 48vh !important;
  overflow: scroll;
  width: 100%;
  padding: 12px;
}
.text-ellipsis-clamp-2 {
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.first-letter-capital {
  text-transform: lowercase;
  display: inline-block;
}

.first-letter-capital::first-line {
  text-transform: capitalize;
}
.h-50 {
  height: 50%;
}
.h-30 {
  height: 30%;
}
.h-70 {
  height: 70%;
}
.h-100 {
  height: 100%;
}
.sticky {
  position: sticky;
  //background-color: #fcfcfc;
  z-index: 999;
}
.top-n1 {
  top: -1px;
}
.top-0 {
  top: 0px;
}
.top-5 {
  top: 5px;
}
.top-43 {
  top: 43px;
}
.top-50 {
  top: 50px;
}
.top-51 {
  top: 51px;
}
.top-63 {
  top: 63px;
}
.top-80 {
  top: 80px;
}
.top-100 {
  top: 100px;
}
.top-120 {
  top: 120px;
}
.top-128 {
  top: 128px;
}
.top-135 {
  top: 135px;
}
.top-152 {
  top: 152px;
}
.top-145 {
  top: 145px;
}
.top-175 {
  top: 175px;
}
.z-index {
  z-index: 1;
}
.z-index-2 {
  z-index: 2;
}
.z-index-100 {
  z-index: 100;
}
.z-index-1000 {
  z-index: 1000 !important;
}
.z-index-1200 {
  z-index: 1200 !important;
}
.opacity-80 {
  opacity: 80%;
}
.h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  font-family: "Nunito";
}

.body2 {
  font-family: "Nunito";
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
}
.body1 {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 18px;
  line-height: 24px;
  font-weight: 300;
  font-family: "Nunito";
}
.min-width-app {
  min-width: 344px;
}
//FOR IOS and Android
.touch-action {
  touch-action: none; //for android
  overscroll-behavior: none; //looks it will also work for ios
}
.overscroll-behavior-none {
  overscroll-behavior: none;
}
.right-0 {
  right: 0px;
}

.visibility-hidden{
   visibility:hidden;
}
.stripe-pay-now-button-dark-theme{
  margin-bottom: 1px;
  margin-top: 20px;
}
.dark-text-color {
  color: #ffffff
}