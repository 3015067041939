#product-box {
    .card-style {
        box-shadow: none;
        border-radius: 10px !important;
    }

    .quantity-button {
         top: 65%;
    }
    .orderID{
        top: 10px;
        left: 10px;
    }
    .orderBtn{
        min-width: 100px;
    }
}
