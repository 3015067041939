.dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-width: 2px;
    border-radius: 2px;
    border-color: #cbc9c9;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    padding: 5px;
    transition: border .24s ease-in-out;
}
.container-upload {
    padding : 10px;
}