#industry-list {
    .swiper {
        width: 100%;
        height: 100%;
    }
    .swiper-slide {
        text-align: center;
        font-size: 18px;
        height: 100%;
        width: max-content !important;
        /* Center slide text vertically */
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
    }

    .swiper-slide img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .swiper-button-next,
    .swiper-button-prev {
        color: black;
        background-color: #967bd3;
        border-radius: 100%;
        width: 45px !important;
        height: 45px !important;
        padding: 10px !important;
    }

    .swiper-button-next:hover,
    .swiper-button-prev:hover {
        background-color: #533a8b;
    }

    .swiper-button-next:after,
    .swiper-button-prev:after {
        font-size: 25px !important;
    }
    // .swiper-button-next.swiper-button-disabled,
    // .swiper-button-prev.swiper-button-disabled {
    //     display: none;
    // }
}