#sale-item-box-detail {
  .tag-bg-color_detail {
    height: 27px;
  }
  .tag-size_detail {
    height: 22px;
    font-size: 11px !important;
  }

  .paper-root2 {
    box-shadow: 0px 0px 3px #d2d2d2 !important;
  }
  .h-70 {
    height: 70% !important;
  }
  .h-30 {
    height: 30% !important;
  }
  .text-styling_detail {
    height: 25px !important;
  }
}
