#product-add {
  .max-table-column-width-0 {}
  .max-table-column-width-1 {}
  .button-label {
    text-transform: none;
  }
  .row-stripe {
    background-color: #fcfcfc;
  }
  .textbox-width {
    min-width: 200px;
  }
  .button-upload {
      min-width: 116px;
  }
  .button-add {
      min-width: 100px;
  }
}
