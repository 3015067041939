#best-seller-carousel {
  .swiper-slide {
    font-size: 18px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .swiper-button-next,
  .swiper-button-prev {
    color: black;
    background-color: white;
    border-radius: 100%;
    width: 45px !important;
    height: 45px !important;
    // padding: 10px !important;
  }

  .swiper-button-next:hover,
  .swiper-button-prev:hover {
    background-color: #dcdcdc;
    box-shadow: 0px 0px 3px #d2d2d2 !important;
  }

  .swiper-button-next:after,
  .swiper-button-prev:after {
    font-size: 25px !important;
  }

  .h-35 {
    height: 35% !important;
  }
}
