.icon-position-close {
  position: absolute;
  left: 0%;
  top: 0%;
  z-index: 99999999;
}

.icon-position-right {
  top: 0px !important;
  right: 39%;
  left: inherit !important;
  z-index: 9999;
}

.menu img {
  width: 100%;
  height: auto;
  background-color: #fff;
  box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset,
    rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
}

@media only screen and (min-width: 1024px) {
    .icon-position-right {
        right: 72%;
    }
}
