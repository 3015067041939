#box-group {
    .title {
      font-weight: bolder;
      text-align: center;
    }
    .opacity:hover {
      opacity: 0.7;
    }
    .paper {
      position: relative !important;
    }
    .droppable {
      border : 5px dashed #b2b2b2;
    }
    .center-text {
      position: absolute;
      color: black;
      background-color: white;
      opacity: 0.8;
      left: 30%;
      top: 45%;
    }
    .p-1-4 {
      padding : 1.45px
    }
    .action-button {
      position: absolute;
      top: 0px;
      right: 0px;
      display: none;
    }
    .action-button-center {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -18px;
      margin-top: -9px;
      display: none;
    }
    .paper:hover {
      .action-button {
        display: block;
      }
      .action-button-center {
        display: block;
      }
    }
    .image-rounded {
      border-radius: 100px;
    }
    .p-relative {
      position: relative;
    }
  }