#best-seller {
  .h-10 {
    height: 10% !important;
  }
  .h-90 {
    height: 90% !important;
  }
  .label {
    clip-path: polygon(0 0, 100% 0, 69% 100%, 0% 100%);
  }
}
