#best-deals-swipper-carousel {
    .swiper {
        width: 100%;
        height: 100%;
    }

    .swiper-slide {
        text-align: center;
        font-size: 18px;
        height: 400px;
        /* Center slide text vertically */
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
    }

    .swiper-slide img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .swiper-button-next,
    .swiper-button-prev {
        color: black;
        border-radius: 100%;
        margin-top: -19%;
    }
    .swiper-button-prev {
        // margin-left: 90%;
        position: absolute;
        float: right;
    }


    .swiper-button-next:after,
    .swiper-button-prev:after {
        font-size: 12px !important;
    }

    .h-35 {
        height: 35% !important;
    }
    .swiper-pagination {
        position: relative !important;
        display: flex;
        justify-content: end;
    }
    .swiper-pagination-bullet {
        background: black !important;
        margin: 5px !important;
        opacity: 1 !important;
    }
    .swiper-pagination-bullet-active {
       border: 2px inset white!important;
       box-shadow: 0px 0px 0px 2px black !important;
    }
}
