#merchant-box-detail {
    // .paper-root2 {
    //     box-shadow: 0px 0px 3px #d2d2d2 !important;
    // }
    .box {
        // background-color: #d9d9d9;
        border-radius: 15px !important;
        background-repeat: no-repeat;
    }
    .card-style {
        box-shadow: 0px 4px 20px -2px rgba(50, 50, 71, 0.04) !important;
        border-radius: 8px !important;
    }
    .tag-style {
        margin-top: 60px !important;
    }
    .delivery-area {
        background-color: #57003a;
        text-align: center;
        z-index: 100;
        position: absolute;
        top: 0;
        left: 0;
        width: 100% !important;
        opacity: 0.7;
        border-top-right-radius: 10px !important;
        border-top-left-radius: 10px !important;
    }
    .img-style {
        height: 90px;
        width: 100%;
        object-fit: cover;
        border-radius: 10px 10px 0px 0px;
    }
    .img-style-blur {
        // filter: blur(4px);
        // -webkit-filter: blur(4px);
        opacity: 0.2;
        height: 90px;
        width: 100%;
        object-fit: cover;
        border-radius: 10px 10px 0px 0px;
    }
    .content-style {
        min-height: 62px;
    }
}
