#product-box {
  .card-style {
    box-shadow: none;
    // border-radius: 10px !important;
    // height: 270px;
  }
  .quantity-button {
    top: 85%;
  }
  .responsive-img-size {
    width: 60px;
    max-width: 100%;
    height: 60px;
    border-radius: 10px;
    object-fit: contain;
  }
  .img-size {
    width: 70px;
    max-width: 100%;
    height: 70px;
    border-radius: 10px;
    object-fit: contain;
  }
  .image-style-plus {
    object-fit: cover !important;
    width: 70px !important;
    height: 70px !important;
  }
  .img-size-prominent {
    width: 100%;
    max-width: 100%;
    max-height: 120px;
    border-radius: 10px;
    object-fit: contain;
  }
  // .product-box {
  //     height: 120px;
  // }
  .product-decription {
    height: 50px;
  }
  .stock {
    background-color: #cb1c91;
    text-align: center;
    z-index: 100;
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    opacity: 0.6;
  }
}
.tooltipHeight {
  max-height: 100px;
  overflow-y: scroll;
}
// @keyframes slideIn {
//   0% {
//       height: 0;
//       opacity: 0;
//   }
//   100% {
//       height: auto;
//       opacity: 1;
//   }
// }

// .slide-in {
//   animation: slideIn 0.3s ease-in-out;
// }
