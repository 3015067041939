.dialog-box-container .title p {
    margin-bottom: 0;
    margin-top: 10px;
    color: #fff;
  }
  
  .dialog-box-container .react-calendar {
    background-color: #2a2e30;
    color: #fff;
    border: none;
  }
  
  .dialog-box-container .react-calendar button {
    color: #fff;
  }
  
  .dialog-box-container .makeStyles-alertdialogbox-46 {
    margin-bottom: 0;
  }