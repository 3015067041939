#shopping-cart-item {
    .h-90 {
        height: 90%;
    }
    .h-10 {
        height: 10%;
    }
    .cart-img {
        border-radius: 27px !important;
    }
    // .text-style{
    //     min-width: 100px;
    // }
    .responsive-img-size {
        width: 60px;
        max-width: 100%;
        height: 60px;
        border-radius: 10px;
        object-fit: contain;
    }
    .img-size {
        width: 70px;
        max-width: 100%;
        height: 70px;
        border-radius: 10px;
        object-fit: contain;
    }
}
