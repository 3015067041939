#slide-to-unlock {
  .swipe-button {
    height: 45px;
    background: #fff;
    border-radius: 25px;
    width: 100%;
    padding-right: 50px;
  }
  .swipe-button-disabled {
    height: 45px;
    background: #cfcfcf;
    border-radius: 25px;
    width: 100%;
    padding-right: 50px;
  }
  .text {
    position: absolute;
    bottom: 0px;
    left: 0px;
    color: #ffffff;
  }

  .root {
    color: #000;
    text-transform: none;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 42px;
  }

  .MuiSlider-thumb::after {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f061";
    box-shadow: none;
    color: #ffffff;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .responsive-breathing-button {
    // margin-top: -21px;
    width: 50px !important;
    height: 50px !important;
    font-size: 25px;
    height: -webkit-fill-available;
    margin-left: 22px;
    top: 22px;
    cursor: grab;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-radius: 50%;
    z-index: 1;
    pointer-events: all;
    background: linear-gradient(180deg, #0fd26a 0%, #0e8b48 100%);
  }

  .breathing-button {
    // margin-top: -13px;
    width: 50px !important;
    height: 50px !important;
    font-size: 25px;
    height: -webkit-fill-available;
    margin-left: 22px;
    top: 22px;
    cursor: grab;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-radius: 50%;
    z-index: 1;
    pointer-events: all;
    background: linear-gradient(180deg, #0fd26a 0%, #0e8b48 100%);
  }
  .thumb-disabled {
    width: 50px !important;
    height: 50px !important;
    font-size: 25px;
    height: -webkit-fill-available;
    margin-left: 22px;
    top: 22px;
    cursor: grab;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-radius: 50%;
    z-index: 1;
    pointer-events: all;
    background: #bdb8b8;
  }
  @-webkit-keyframes breathing {
    0% {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }

    25% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }

    60% {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }

    100% {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }
  }

  @keyframes breathing {
    0% {
      -webkit-transform: scale(1.1);
      -ms-transform: scale(1.1);
      transform: scale(1.1);
    }

    25% {
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1);
    }

    60% {
      -webkit-transform: scale(1.1);
      -ms-transform: scale(1.1);
      transform: scale(1.1);
    }

    100% {
      -webkit-transform: scale(1.1);
      -ms-transform: scale(1.1);
      transform: scale(1.1);
    }
  }
}
