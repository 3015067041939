#product-page-header {
    .paper {
        height: 200px;
        // max-width: 571px;
        overflow: hidden;
        width: 100%;
    }
    .paperOnMobile {
        height: 220px;
        overflow: hidden;
        width: 100%;
    }
    .img-size {
        object-fit: cover;
    }
    .icon-opacity {
        background-color: rgba(0, 0, 0, 0.6);
        border-radius: 10px !important;
    }
    .circular-img-box {
        height: 70px;
        width: 70px;
        object-fit: none;
    }
    .circular-img-box-responsive {
        height: 50px;
        width: 50px;
        object-fit: none;
    }
    .info-icon {
        background-color: #b9b9b9;
    }
}
