#site-label-textfield-v5 {
    min-height: 100vh;
    background: #ffffff;
    border-radius: 10px;
}
.border-radius-10 {
    border-radius: 10px;
}
#site-label-text-field-v5 {
    .MuiOutlinedInput-adornedStart {
        padding-left: 0px !important;
    }
    .MuiInputAdornment-positionEnd {
        max-width: 80%;
    }
}
.textfield-border {
    border: 1px solid #707070 !important;
}
