#sub-category-content {
    .slick-prev:before {
        content: "" !important;
    }
    .slick-next:before {
        content: "" !important;
    }
    .slick-next {
        right: 0px !important;
    }
    .slick-prev {
        position: absolute !important;
        z-index: 1 !important;
    }
    #left_arrow_carousel-product {
        font-size: 14px !important;
    }
    #right_arrow_carousel-product {
        font-size: 14px !important;
    }
    .carousel-product {
        outline: none !important;
        box-shadow: 0px 0px 3px #525252 !important;
    }
    .carousel-product:hover {
        background-color: rgb(252, 252, 252) !important;
    }
    .swiper {
        width: 100%;
        height: 100%;
    }
    .swiper-button-next,
    .swiper-button-prev {
        border-radius: 20px;
        border: 1px solid #d7efde;
        background-color: #ddf6e5;
        width: 25px;
        height: 25px;
        color: black;
        border-radius: 100%;
        top: 70% !important;
    }
    .swiper-button-next:after {
        font-size: 12px;
    }
    .swiper-button-prev:after {
        font-size: 12px;
    }
    .swiper-slide {
        display: flex;
        align-items: center;
        height: auto !important;
        width: 200px !important;
    }
    .swiper-slide-responsive {
        height: auto !important;
        width: 104px !important;
    }
    .swiper-slide-sub-category {
        height: auto !important;
        width: auto !important;
    }
    // .swiper-button-next.swiper-button-disabled,
    // .swiper-button-prev.swiper-button-disabled {
    //     display: none;
    // }
    .bg-style {
        background-color: #f8f8f8 !important;
        z-index: 1200;
        height: auto !important;
        margin-top: 0 !important;
        top: 0 !important;
    }
}
#category-chip .jss46{
    width: 100% !important;
}