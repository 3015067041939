#order-history-detail-dialog {
  .circle-style {
    // width: 90px;
    // height: 90px;
    border: 1px solid #ebe9ee;
  }
  .support {
    background-color: aqua;
  }
}
.cover-image {
  width: 100%;
  height: 100px;
  object-fit: cover;
}

//content

.css-gwf1ph-MuiDialogContent-root {
  padding: 5px 10px !important;
}
.cancel-icon {
  z-index: 1;
}
.MuiFormControlLabel-label {
  color: #cb1c91 !important;
  font-weight: 500;
}
.timer-box {
  width: 60px;
  margin: 0 auto;
  height: 60px;
  margin-top: 5px;
  background-color: #f7f7f7;
  opacity: 80%;
}
