.stepper{
    .MuiStepLabel-root.MuiStepLabel-alternativeLabel {
        flex-direction: column-reverse;
    }
    .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
        margin-top: 16px;
        width: max-content;
    }
    .MuiStepLabel-labelContainer {
        width: auto;
    }
    .MuiStepConnector-lineHorizontal {
        border-top-style: solid;
        border-top-width: 3px;
        border-color: #e06dba
    }
    .font-size-small {
        font-size: 0.5rem !important;
    }
}
