#product-detail {
    .draggable-panel {
        height: 80vh;
        width: 341px;
        border-right: 1px solid #e6e6e6;
        padding : 10px;
        overflow-y: auto;
    }
    .droppable-panel {
        padding : 10px;
    }
    .Droppable {
        width : 100%;
        height: calc( 100% - 36px );
    }
    .draggable {
        width : 100%;
    }
    .draggable-item { 
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .droppable-item {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .disable-drag {
        -moz-user-select: none;
        -webkit-user-drag: none;
        -webkit-user-select: none;
        -ms-user-select: none;
    }
    .selected-box {
        background: #eeeeee;
    }
    .box-check-icon {
        position: absolute;
        right: 0;
        padding: 2px;
    }
}
