#sidebar {
    .MuiDrawer-root {
        margin-top: 55px !important;
    }
    .MuiDrawer-paper {
        background-color: transparent !important;
        width: 100% !important;
        margin-top: 55px !important;
    }
    .MuiBackdrop-root {
        margin-top: 55px !important;
    }
    .active {
        font-weight: 800 !important;
    }
}
