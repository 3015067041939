#sale-item-box {
  .w-15 {
    width: 15% !important;
  }
  .tag-bg-color {
    height: 27px;
  }
  .tag-size {
    height: 22px;
    font-size: 11px !important;
  }
  .paper-root2 {
    box-shadow: 0px 0px 3px #d2d2d2 !important;
  }
}
