#order-history-card {
    .cancel-order {
        background-color: rgba(0, 0, 0, 0.6);
        border-radius: 10px !important;
    }
    .tooltip-style {
        max-height: 100px;
        overflow-y: auto;
    }
    .MuiTooltip-tooltipPlacementBottom {
        max-width: 400px !important;
    }
}
