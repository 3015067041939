.wrapperElement{
    background: linear-gradient(to bottom, #141423 90%, #141423 10%);
    height: 100vh;
}

.transparentBackground{
  background-color: transparent !important;  
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapperElement .no-padding{
    padding: 0 !important;
}

.wrapperElement .no-padding .pt-4{
    padding-top: 0 !important;
}